.subjectContent[data-v-e88a9122] {
  display: flex;
  overflow: hidden;
  color: #1890ff;
  cursor: pointer;
}
.subjectContent .label[data-v-e88a9122] {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  height: auto;
}
.editBtn[data-v-e88a9122]:hover {
  text-decoration: underline;
}
